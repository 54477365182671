<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="()=>{
            this.api.isSuccesful = false;
            this.fetch();
        }" :api="this.api"/>
        <v-data-table
            v-if="!api.isLoading"
            :items="filteredData"
            :search="search"
            :headers="dataHeader">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}

            </template>
            <template
                v-slot:item.job_schedulers_status="{item}">
                    <v-chip
                        small
                        v-if="item.job_schedulers_status==='subscribed'"
                        color="success">
                            {{ item.job_schedulers_status }}
                    </v-chip>
                    <v-chip
                        small
                        v-if="item.job_schedulers_status === 'pending'"
                        color="warning"
                        outlined>
                        {{ item.job_schedulers_status }}
                    </v-chip>
            </template>
            <template
                v-slot:item.job_schedulers_data="{item}">
                <ul>
                    <li>
                        {{ item.plan_name }}
                    </li>
                    <!-- <li
                        v-if="item.is_default_payment_method!=null">
                        {{ "Default payment method : true" }}
                    </li>
                    <li
                        v-else>
                        {{ "Default payment method : false" }}
                    </li> -->
                    <li
                        v-if="item.stripe_coupon!=null"
                        v-html="item.stripe_coupon">
                        <!-- {{item.stripe_coupon}} -->
                    </li>
                </ul>
            </template>
            <template
                v-slot:item.created_by_username="{item}">
                <ACopyAbleText
                    :text="item.created_by_username"/>
            </template>
            <template
                v-slot:item.company_name="{item}">
                <router-link
                    :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.company_name }}
                </router-link>
            </template>
            <template
                v-slot:item.job_schedulers_date="{item}">
                <!-- <ADateFormat 
                :date="item.job_schedulers_date"/> -->
                <ACopyAbleText
                    :text="item.job_schedulers_date"/>
            </template>
            <template
                v-slot:item.job_schedulers_created_at="{item}">
                <!-- <ADateFormat
                :data="item.job_schedulers_created_at"/> -->
                <ACopyAbleText
                    :text="item.job_schedulers_created_at"/>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Scheduled Subscriptions
                                </v-toolbar-title>
                            </v-row>
                            <AFilterWithoutRefresh
                                @reset="(data)=>{
                                    filteredData = data
                                }"
                                @filter="(data)=>{
                                    filteredData = data;
                                }"
                                @refresh="fetch"
                                :data="data"
                                :filterHeaders="dataHeader"/>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template
                v-slot:item.action="{item}">
                <v-menu
                    offset-y>
                    <template
                        v-slot:activator="{on, attrs}">
                        <v-btn
                            small
                            v-bind="attrs"
                            v-on="on"
                            color="primary">
                            Actions
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(data,index) in actions"
                            :key="index"
                            @click="executeActions(data,item)">
                            {{ data.text }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import AFilterWithoutRefresh from '../../components/common/filter/AFilterWithoutRefresh.vue';
export default {
    components:{
    ASuccessWithoutReload,
    AFilterWithoutRefresh,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        currentItem:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Schedule Date',
                value:'job_schedulers_date'
            },
            {
                text:'Details',
                value:'job_schedulers_data'
            },
            {
                text:'Status',
                value:'job_schedulers_status',
            },
            {
                text:'Created by',
                value:'created_by_username'
            },
            {
                text:'Created date',
                value:'job_schedulers_created_at'   
            },
            {
                text:'Action',
                value:'action'
            }
        ],
        actions:[
            {
                text:'Edit',
                value:'PageEditSubscriptionScheduler',
            },
            {
                text:'Delete schedule',
                value:'cancelSubscription',
            }
        ],
        filteredData:null,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === 'getCompaniesSchedulerSubscriptions') {
                this.data = resp.data;
                this.filteredData = this.$_.clone(this.data);
            }
            if(resp.class === 'deleteSchedulerSubscription') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully delete the scheduled subscription";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchSubscriptionScheduler());
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchSubscriptionScheduler() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscriptions/scheduler";
            return tempApi;
        },

        resetFilter() {
            this.filteredData = this.$_.clone(this.data);
        },  
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            this.$api.fetch(this.deleteSubscription());
        },
        deleteSubscription() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscriptions/scheduler/delete/"+this.currentItem.job_schedulers_id;
            return tempApi;
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },

        executeActions(data,item) {
            this.currentItem = null;
            if(data.value==='cancelSubscription') {
                this.currentItem = item;
                this.isPending = true;
                return true;
            }
            this.$router.push({name:data.value,params:{'id':item.job_schedulers_id}});
        },
    }
}
</script>